<template>
  <div class="common-copyright-container">
    <h2>Copyright © InspiringHK Sports Foundation</h2>
    <div class="content">
      <p>
        <strong>Copyright © InspiringHK Sports Foundation</strong>
      </p>
      <div v-for="(item, index) in List" :key="index">
        <p>{{ item.txt }}</p>
        <br>
      </div>
      <br>
      <p>
        <strong>Your acceptance of this statement</strong>
      </p>
      <p>
        By using our sites, you consent to the collection and use of
        information by InspiringHK Sports Foundation in accordance with this
        statement. If you do not agree to this statement, please do not use
        our sites.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
      List: [
        {
          txt:
            'InspiringHK Sports Foundation offers its information on the Internet essentially for the benefit of individuals interested in engaging the underprivileged in sport and unleashing their human potential.',
        },
        {
          txt:
            'If you wish to mirror, reproduce, republish or translate any of the information for wider dissemination, please email us at info@inspiringhk.org with details of such proposed use, including the nature and size of the intended audience.',
        },
        {
          txt:
            'The Foundation reserves all copyrights on material on its Web pages, including photographs and graphic designs. In the case of some publications, The Foundation has licensed the rights to third parties.',
        },
        {
          txt:
            'The use of The Foundation Internet material for any purpose, including repackaging in hard copy or compilation for CD-ROM or any other electronic media, is prohibited unless prior written permission is obtained.',
        },
        {
          txt:
            'The unauthorised use of The Foundation name and logo is against domestic law and is expressly forbidden.',
        },
      ],
    }
  },
}
</script>
